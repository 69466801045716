import { Link } from "react-router-dom"
// import ReactWOW from 'react-wow'

const CourseCard = ({
    title,
    desc,
    amount,
    image,
    course
}) => {
    return <div className="col-md-3 col-sm-6 col-xs-12">
    <div animation='fadeIn' className="course_figure frombottom wow">
      <span className="c_price">{amount+'₹'}</span>
      <div className="course_thumb">
        <img
          src={image}
          alt=""
          className="img-responsive"
        />
        <div className="course_overlay">
          <div className="overlay_inner">
            <Link to={{
              pathname:'order',
              state: { fromHomePage: 'order', courseSelected: true, courseObject : course  }
            }} className="book_btn red_btn">
              Book Now
            </Link>
          </div>
        </div>
      </div>
      <div className="course_desc">
        <h4>{title}</h4>
        <p>{desc}</p>
      </div>
    </div>
  </div>
}


export default CourseCard