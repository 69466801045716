import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

function TestimonialSlider({testimonials}) {
  console.log(testimonials);
  return (
    <div className="ds_slider_section">
      <OwlCarousel
        className="owl-carousel owl-theme testimonial_slider owl-responsive-1000 owl-loaded"
        loop
        margin={0}
        items={1}
        center={true}
        responsiveClass={true}
        animateIn={"zoom"}
				animateOut={"fadeOut"}
      >
        {testimonials.map((testimonial)=>(<div className="item" key={'testimonial' + testimonial.id} >
          <div className="testimonial_cover">
            <i className="fa fa-quote-left" />
            <p>{testimonial.testimonial}</p>
            <h3>{testimonial.Name}</h3>
            <h5>{testimonial.position}</h5>
          </div>
        </div>))}
        {/* item End */}
      </OwlCarousel>
    </div>
  );
}

export default TestimonialSlider;
