import Header from "./../compoents/common/Header";
import Footer from "./../compoents/common/Footer";
import { useEffect, useState } from "react";
import {  homePageApi, createOrderApi,avalibilityApi,createOrderStartApi, baseUrl } from "../utils/apis";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";
import 'react-notifications/lib/notifications.css';
import { NotificationManager} from 'react-notifications';
import DatePicker from 'react-date-picker';
import 'react-date-picker/dist/DatePicker.css';
import moment from 'moment';
import { useForm } from "react-hook-form";
import { Link } from "react-router-dom";

const phoneRegExp =
  /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const SignupSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  email: Yup.string().email().required("Required"),
  phone: Yup.string()
    .matches(phoneRegExp, "Phone number is not valid")
    .length(10, "Phone number should be at least 10 digits")
    .required("Required"),
  addressLine1: Yup.string().required("Required"),
  addressLine2: Yup.string().required("Required"),
  city: Yup.string().required("Required"),
  state: Yup.string().required("Required"),
  date: Yup.date().required("Required"),
  slote: Yup.string().required("Required"),
});

const OrderPage = (props) => {
  const [homePageState, setHomePageState] = useState({});
  const [orderData, setOrderData] = useState(null);
  const [avalibilityData, setAvalibilityData] = useState([]);
  const [avalibilityList, setAvalibilityList] = useState([]);
  const [courseInfo, setcourseInfo] = useState(null);
  useEffect(() => {
    
    if(props.location.state && props.location.state.courseObject){
      console.log(props.location.state.courseObject);
      setcourseInfo(props.location.state.courseObject)
    }else{
      props.history.push('/')
    }
    homePageApi().then((data) => {
      console.log(data);
      if (data) {
        setHomePageState(data);
      }
    });
    avalibilityApi().then((data) => {
      if (data) {
        setAvalibilityData(data);
      }
    });
  }, []);

  const getSlotes = function(values){
    // find slotes that
    const day  = moment(values).day()
    const days = ['Sunday','Monday','Tuesday','Wednesday','Thursday','Friday','Saturday']
    if(day <= 0){
      NotificationManager.error('Select another day', "We are not working on Sunday.");
    }else{
      if(days[day] && avalibilityData && avalibilityData[days[day]]){
        console.log(avalibilityData[days[day]].SloteLists);

        if(avalibilityData[days[day]].SloteLists && avalibilityData[days[day]].SloteLists.length > 0){
          setAvalibilityList(avalibilityData[days[day]].SloteLists);
        }else{
          setAvalibilityList(avalibilityData[days[day]].SloteLists);
          NotificationManager.error('Select another day', "We are not working on "+ days[day]+".");  
        }
      }else{
        NotificationManager.error('Select another day', "We are not working on "+ days[day]+".");
      }
    }
  }

  return (
    <>
      <Header
        emailText={homePageState.contactUs}
        phoneText={homePageState.PhoneNumber}
      />

      <div class="full_width ds_page_banner">
        <div class="black_overlay">
          <div class="container">
            <div class="row">
              <div class="page_content_wrap">
                <div class="page_heading">
                  <h1>{"Book Your Course"}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="courses" className="full_width courses_section pb-5">
        {orderData && <div className="container" >
            <h2> 🥳 Thanks you  🎉 </h2>
            <h4> Your Payment Number is <b style={{color: 'green'}} >{orderData.razorpay_payment_id}</b></h4>
        </div>}
        <div className="container" style={{display: orderData ?'none':'block'}} >
          <h3 className="mb-5">Add Your Information</h3>
          <div className="row" >
            <div className="col-sm-6 col-xs-12">
              <div className="">
              {courseInfo && <div class="card mb-3" style={{maxWidth:'540px'}}>
                <div class="row no-gutters">
                  <div class="col-md-4">
                    <img src={baseUrl + courseInfo.Photo.url} class="card-img" alt="card" />
                  </div>
                  <div class="col-md-8">
                    <div class="card-body">
                      <h5 class="card-title">{courseInfo.Name}</h5>
                      <p class="card-text">{courseInfo.Description}</p>
                    </div>
                  </div>
                </div>
              </div>}
                <div className="row">
                  <Formik
                    initialValues={{
                      name: "",
                      email: "",
                      phone: "",
                      addressLine1: "",
                      addressLine2: "",
                      city: "",
                      state: "",
                      date: '',
                      slote: '',
                      policy: false
                    }}
                    validationSchema={SignupSchema}
                    onSubmit={(values, { resetForm }) => {
                      // same shape as initial values
                      const startAt = moment(values.date).set({'hour':values.slote.split(':')[0],'minute':values.slote.split(':')[1]})
                      const endAt =  moment(values.date).set({'hour':parseInt(values.slote.split(':')[0])+1,'minute':values.slote.split(':')[1]})
                      
                      createOrderApi({
                        courseId: "2",
                        order: {
                          ...values,
                          startAt: startAt.format('YYYY-MM-DDTHH:mm:ss'),
                          endAt: endAt.format('YYYY-MM-DDTHH:mm:ss')
                        }
                      }).then((data)=>{
                        if(data.status && data.status == 404){
                          NotificationManager.error('Oops', data.msg );
                        }else{

                          var options = {
                            key: "rzp_live_lofCRRHqbYdCuS",
                            name: "Sai Driving School",
                            order_id: data.order.id, // For one time payment
                            prefill: {
                              name: values.name,
                              email: values.email,
                              contact: values.phone,
                            },
                            theme: {
                              color: "#3399cc",
                            },
                            // This handler function will handle the success payment
                            handler: function (response) {
                              if(typeof response.razorpay_payment_id == 'undefined' ||  response.razorpay_payment_id < 1){
                                NotificationManager.error('Payment Failed', "If something went wrong then Contact us.");
                              }else{
                                createOrderStartApi({
                                  courseId: "2",
                                  order: {
                                    ...values,
                                    razorpay_payment_id:response.razorpay_payment_id,
                                    order_id:data.order.id,
                                    startAt: startAt.format('YYYY-MM-DDTHH:mm:ss'),
                                    endAt: endAt.format('YYYY-MM-DDTHH:mm:ss')
                                  }
                                }).then(()=>{
                                  resetForm();
                                })
                                NotificationManager.success('Payment Done', "Thanks for your order");
                                setOrderData(response)
                              }
                            },
                          };
                          var rzp1 = new window.Razorpay(options);
                          rzp1.open();
                        }
                      })
                      console.log(values);
                    }}
                  >
                    {({ errors, touched,setFieldValue, values }) => (
                      <Form className="">
                        <div className="row">
                          <div className="col-md-12 col-xs-12">
                            <div class="form-floating mb-3">
                              <Field
                                type="text"
                                class={touched.name && errors.name?"form-control is-invalid":"form-control"}
                                id="fullName"
                                placeholder="John Doi"
                                name="name"
                              />
                              {touched.name && errors.name && (
                                <div class="invalid-feedback mx-2">
                                  {errors.name}
                                </div>
                              )}
                              <label for="fullName">Full Name</label>
                            </div>
                          </div>
                          <div className="col-md-6 col-xs-12">
                            <div class="form-floating mb-3">
                              <Field
                                type="email"
                                className={touched.email && errors.email?"form-control is-invalid":"form-control"}
                                id="emailInput"
                                placeholder="name@example.com"
                                name="email"
                              />
                              <label for="emailInput">Email address</label>
                              {touched.email && errors.email && (
                                <div class="invalid-feedback mx-2">
                                  {errors.email}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-xs-12">
                            <div class="form-floating mb-3">
                              <Field
                                type="text"
                                className={touched.phone && errors.phone?"form-control is-invalid":"form-control"}
                                id="phone"
                                placeholder="name@example.com"
                                name="phone"
                              />
                              <label for="phone">Mobile Number</label>
                              {touched.phone && errors.phone && (
                                <div class="invalid-feedback mx-2">
                                  {errors.phone}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-xs-12">
                            <div class="form-floating mb-3">
                              <Field
                                type="text"
                                className={touched.addressLine1 && errors.addressLine1?"form-control is-invalid":"form-control"}
                                id="addressLine1"
                                placeholder="name@example.com"
                                name="addressLine1"
                              />
                              <label for="addressLine1">Address Line 1</label>
                              {touched.addressLine1 && errors.addressLine1 && (
                                <div class="invalid-feedback mx-2">
                                  {errors.addressLine1}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-xs-12"></div>
                          <div className="col-md-6 col-xs-12">
                            <div class="form-floating mb-3">
                              <Field
                                type="text"
                                className={touched.addressLine2 && errors.addressLine2?"form-control is-invalid":"form-control"}
                                id="addressLine2"
                                placeholder="name@example.com"
                                name="addressLine2"
                              />
                              <label for="addressLine2">Address Line 2</label>
                              {touched.addressLine2 && errors.addressLine2 && (
                                <div class="invalid-feedback mx-2">
                                  {errors.addressLine2}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-xs-12"></div>
                          <div className="col-md-6 col-xs-12">
                            <div class="form-floating mb-3">
                              <Field
                                type="text"
                                className={touched.city && errors.city?"form-control is-invalid":"form-control"}
                                id="city"
                                placeholder="name@example.com"
                                name="city"
                              />
                              <label for="city">City</label>
                              {touched.city && errors.city && (
                                <div class="invalid-feedback mx-2">
                                  {errors.city}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-xs-12">
                            <div class="form-floating mb-3">
                              <Field
                                type="text"
                                className={touched.state && errors.state?"form-control is-invalid":"form-control"}
                                id="state"
                                placeholder="name@example.com"
                                name="state"
                              />
                              <label for="state">State</label>
                              {touched.state && errors.state && (
                                <div class="invalid-feedback mx-2">
                                  {errors.state}
                                </div>
                              )}
                            </div>
                          </div>
                          <div className="col-md-6 col-xs-12 mb-3 mt-2">
                            <div class="mb-3">
                              <label for="date" className="text-black-800 mx-2" >Select Date</label>
                              <DatePicker
                                onChange={(value)=>{
                                  if(value){
                                    setFieldValue('date',value)
                                    getSlotes(value)
                                  }
                                }}
                                value={values.date}
                                format={"dd-MM-y"}                    
                              />
                              {touched.date && errors.date && (
                                <div class="alert alert-danger m-2">
                                  {"Please Select Date"}
                                </div>
                              )}
                              {touched.slote && errors.slote && (
                                <div class=" alert alert-danger m-2">
                                  {"Please Select Slote"}
                                </div>
                              )}
                            </div>
                          </div>
                          {avalibilityList && avalibilityList.length > 0 && <div className="col-md-6 col-xs-12 mb-3 mt-2">
                            <div class="mb-3">
                              <Field className="form-control" as="select" name="slote" onChange={(value)=>{
                                setFieldValue('slote',value.target.value)
                              }} >
                                <option value="" >Select Your Slote</option>
                                {avalibilityList && avalibilityList.map((slote)=>{
                                  return <option key={slote.id} value={slote.StartTime} >{slote.SlotName}</option>
                                })}
                              </Field>
                            </div>
                          </div>}
                          <div className="col-lg-12 col-xs-12 mb-5">
                          <label htmlFor="policy" onClick={()=>{
                            // if(values.policy === true) {
                            //   setFieldValue('policy', !values.policy)
                            // } 
                            // if(values.policy === true ){
                            //   setFieldValue('policy', null)
                            //   debugger;
                            // }
                            setFieldValue('policy', !values.policy)
                            console.log(values)
                          }} >
                            <Field  type="checkbox" name="policy"  /> I have read and agree to the terms .
                          </label>
                          <Link to={'/policy'}  style={{
                              color: "#000",
                              fontSize: "15px",
                              marginLeft: "0",
                              color: 'blue',
                              cursor: 'pointer'
                            }}  > Read Privacy Policy </Link>, <Link to={'/terms'}  style={{
                              color: "#000",
                              fontSize: "15px",
                              marginLeft: "0",
                              color: 'blue',
                              cursor: 'pointer'
                            }}  > Terms Policy </Link> and <Link to={'/refunds-policy'}  style={{
                              color: "#000",
                              fontSize: "15px",
                              marginLeft: "0",
                              color: 'blue',
                              cursor: 'pointer'
                            }}  > Refund Policy </Link> <br />
                          <p style={{
                              color: "#000",
                              fontSize: "10px",
                              marginLeft: "0",
                              textDecoration: "underline",
                            }}  >This amount is non refundable.  </p>
                            
                          </div>
                          <div className="col-lg-12 col-xs-12">
                            <button
                              type={"submit"}
                              className="submit_btn red_btn"
                            >
                              Go to Payment
                            </button>
                          </div>
                          <div
                            className="col-lg-12 col-xs-12"
                            id="err_msg"
                          ></div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer
        aboutText={homePageState.About}
        addressText={homePageState.Address}
        emailText={homePageState.contactUs}
        phoneText={homePageState.PhoneNumber}
      />
    </>
  );
};

export default OrderPage;
