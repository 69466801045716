import Header from "./../compoents/common/Header";
import Footer from "./../compoents/common/Footer";
import { useEffect, useState } from "react";
import { getGallaryImages, homePageApi,baseUrl } from "../utils/apis";
import Gallery from "react-grid-gallery";

const GalleryPage = () => {
  const [homePageState, setHomePageState] = useState({});
  const [pageData, setPageData] = useState([]);

  useEffect(() => {
    homePageApi().then((data) => {
      if (data) {
        setHomePageState(data);
      }
    });
    getGallaryImages().then((data) => {
      const IMAGES = [];

      for (let y = 0; y < data.length; y++) {
          const element = data[y];
          IMAGES.push({
            src: baseUrl + element.Photos.url,
            thumbnail: baseUrl + element.Photos.formats.thumbnail.url,
            thumbnailWidth: element.Photos.formats.thumbnail.width,
            thumbnailHeight:element.Photos.formats.thumbnail.height,
            caption: element.caption,
          },)
      }
      setPageData(IMAGES);

      console.log(data);
    });
  }, []);

  return (
    <>
      <Header
        emailText={homePageState.contactUs}
        phoneText={homePageState.PhoneNumber}
      />

      <div class="full_width ds_page_banner">
        <div class="black_overlay">
          <div class="container">
            <div class="row">
              <div class="page_content_wrap">
                <div class="page_heading">
                  <h1>{"Gallary"}</h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="courses" className="full_width contact_section">
        <div className="container">
          <div className="row">
            {/* heading section */}
            <Gallery images={pageData} />
          </div>
        </div>
      </div>
      <Footer
        aboutText={homePageState.About}
        addressText={homePageState.Address}
        emailText={homePageState.contactUs}
        phoneText={homePageState.PhoneNumber}
      />
    </>
  );
};

export default GalleryPage;
