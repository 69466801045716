import "./loadJquery";
import 'bootstrap/dist/css/bootstrap.min.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import HomePage from "./pages/Homepage";
import AboutPage from "./pages/AboutPage";
import ContactPage from "./pages/ContactPage";
import OrderPage from "./pages/OrderPage";
import GalleryPage from "./pages/GallaryPage";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsPage from "./pages/Terms";
import RefundPage from "./pages/Refund";


import "./index.css";
import { ThemeProvider } from "./context/themecontext";
import Helment from "react-helmet";
import {NotificationContainer} from 'react-notifications';

function App() {
  
  return (
    <div className="App">
        <Helment>
          <title>Sai Driving School</title>
          <meta name="description" content="get your licence from sai driving school" />
        </Helment>
      <ThemeProvider>
        <NotificationContainer />
        <Router>
          <div>
            <Switch>
              <Route path="/about" component={AboutPage} />
              <Route path="/contact" component={ContactPage} />
              <Route path="/order" component={OrderPage} />
              <Route path="/gallary" component={GalleryPage} />
              <Route path="/policy" component={PrivacyPolicy} />
              <Route path="/terms" component={TermsPage} />
              <Route path="/refunds-policy" component={RefundPage} />
              <Route path="/" component={HomePage} />
            </Switch>
          </div>
        </Router>
      </ThemeProvider>
    </div>
  );
}

export default App;
