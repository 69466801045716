import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import slide1 from './../../assets/images/orkun-azap-_c7haaSAcIg-unsplash.jpg';
import slide2 from './../../assets/images/beautiful-young-latin-woman-driving-her-brand-new-car-showing-her-thumb-up.jpg';
import { Link } from 'react-router-dom';

function BannerSlider() {
    return (<div className="ds_slider_section">
    <OwlCarousel className='owl-carousel owl-theme home_slider'loop margin={0} items={1}>
      {/* item Start */}
      <div className="item">
        <div className="slides" style={{backgroundImage: 'url('+slide1+')'}}>
          <div className="slider_caption_wrap">
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-sm-12 col-xs-12">
                <div className="slider_caption">
                    <h3>Best For You</h3>
                    <h2>Make safe &amp; Succesfull Drive With Us</h2>
                    <p>
                      Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC.
                    </p>
                    <Link to="/#courses" onClick={()=>{
                      window.location.href = "#courses";
                    }} className="red_btn slide_btn"> Join Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* item End */}
      {/* item Start */}
      <div className="item">
        <div className="slides" style={{backgroundImage: 'url('+slide2+')'}}>
          <div className="slider_caption_wrap">
            <div className="container">
              <div className="row">
                <div className="col-lg-8 col-xs-12 col-lg-offset-2">
                  <div className="slider_caption">
                    <h3>Best For You</h3>
                    <h2>Make safe &amp; Succesfull Drive With Us</h2>
                    <p>
                      Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots in a piece of classical Latin literature from 45 BC.
                    </p>
                    <Link to="/#courses" onClick={()=>{
                      window.location.href = "#more_courses";
                    }} className="red_btn slide_btn"> Join Now</Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* item End */}
    </OwlCarousel>
  </div>);
}

export default BannerSlider