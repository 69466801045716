import { Link } from "react-router-dom";

function Footer({
  aboutText,
  addressText,
  emailText,
  phoneText
}) {
    return (
        <div className="ds_footer_section">
        <div className="container">
          <div className="row">
            <div className="footer_widget_cover row justify-content-between">
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="widget widget_text">
                  <h3 className="widget-title">
                    About Us
                  </h3>
                  <div className="textwidget">
                    <p>{aboutText}</p>
                  </div>
                </div>
              </div>
              {/* <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="widget widget_text">
                  <h3 className="widget-title">
                    Open Hours
                  </h3>
                  <ul>
                    <li><p>Monday To Friday : 10am to 7pm</p></li>
                    <li><p>Saturday: 10am - 6pm</p></li>
                    <li><p>Sunday: Closed</p></li>
                  </ul>
                </div>
              </div> */}
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="widget widget_links">
                  <h3 className="widget-title">
                    Usefull Links
                  </h3>
                  <ul style={{cursor:"pointer"}} >
                    <li><Link to="/policy">Privacy Policy</Link></li>
                    <li><Link to="/terms">Terms & condition</Link></li>
                    <li><Link to="/refunds-policy">Refunds Policy</Link></li>
                    <li><Link to="/contact">Contact us </Link></li>
                  </ul>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="widget address_widget">
                  <h3 className="widget-title">
                    Stay Connected
                  </h3>
                  <ul className="list-style-one">
                    <li><i className="fa fa-map-marker" aria-hidden="true" />{addressText}</li>
                    <li><i className="fa fa-phone" aria-hidden="true" />{phoneText}</li>
                    <li><i className="fa fa-envelope-open-o" aria-hidden="true" />{emailText}</li>
                  </ul>
                </div>
              </div>
              <div>
              </div>
            </div>
            <div className="col-lg-12 col-xs-12">
              <div className="copyright_footer">
                <p>
                  {`Copyright © 2021, Build ❤ with DuneScript `}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      );
}

export default Footer