import Logo from './../../assets/images/oie_KMyr2MhIYLv8.svg';
import { useState } from 'react';

function Header({
  emailText,
  phoneText
}) {
  const [open, setopen] = useState(false)
    return (
      <>
        <div className={'header'}>
          {/* header Top Section Start */}
          <div className="header_top d-sm-none d-md-block d-none d-sm-block">
            <div className="container">
              <div className="row">
                <div className="col-sm-12 col-xs-12">
                  <ul className="contact_desc">
                    <li><i className="fa fa-envelope-open" aria-hidden="true" /> {emailText}</li>
                  </ul>
                  <ul className="timing_shed">
                    <li><i className="fa fa-phone" aria-hidden="true" /> {phoneText}</li>
                    {/* <li><i className="fa fa-clock-o" aria-hidden="true" /> Mon - Fri 10am-7:pm </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* header Top Section End */}
          {/* header menu section start */}
          <div className="header_menu_section" >
            <div className="container">
              <div className="row">
                <div className="col-lg-12 col-xs-12">
                  <div className="ds_logo">
                    <a href="/">
                      <img src={Logo}  className="logo" alt="logo" />
                    </a>
                  </div>
                  <div className={open?"nav_toggle toggle_open":'nav_toggle'} onClick={()=>{setopen(!open)}}>
                    <i />
                    <i />
                    <i />
                  </div>
                  <div className={open?"header_right_menu menu_open":"header_right_menu"}>
                    <ul className="menu"> 
                      <li><a href="/">Home</a></li>
                      <li><a href="/about">About</a></li>
                      <li><a href="/#service">services</a></li>
                      <li><a href="/gallary">Gallary</a></li>
                      <li><a href="/#courses">Courses</a></li>
                      <li><a href="/contact">contact us</a></li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* header menu section end */}
        </div>
        </>
      );
}

export default Header