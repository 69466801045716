import axios from "axios";
export const baseUrl = "http://206.189.142.177:1337";

// export const baseUrl = "http://localhost:1337";

export function courseApi() {
    return axios.get(baseUrl + '/courses').then((response)=>{
        return response.data
    });
}

export function homePageApi() {
    return axios.get(baseUrl + '/home-page').then((response)=>{
        return response.data
    });
}

export function pageDataApi(page) {
    return axios.get(baseUrl + '/pages?pageName='+page).then((response)=>{
        return response.data
    });
}

export function avalibilityApi() {
    return axios.get(baseUrl + '/availibility').then((response)=>{
        return response.data
    });
}


export function createOrderApi(data) {
    return axios.post(baseUrl + '/orders/create', data).then((response)=>{
        return response.data
    });
}

export function createOrderStartApi(data) {
    return axios.post(baseUrl + '/orders/start', data).then((response)=>{
        return response.data
    });
}

export function getGallaryImages() {
    return axios.get(baseUrl + '/gallaries').then((response)=>{
        return response.data
    });
}

export function saveFeedback(sdata) {
    return axios.post(baseUrl + '/feedbacks',sdata).then((response)=>{
        return response.data
    });
}