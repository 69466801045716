import Header from "./../compoents/common/Header";
import Footer from "./../compoents/common/Footer";
import { useEffect, useState } from "react";
import { pageDataApi,homePageApi, baseUrl} from "../utils/apis";
import ReactMarkdown from 'react-markdown'

const AboutPage = () => {
  const [homePageState, setHomePageState] = useState({});
  const [pageData, setPageData] = useState({});

  useEffect(() => {
    homePageApi().then((data)=>{
      console.log(data);
      if(data){
        setHomePageState(data)
      }
    });
    pageDataApi('about').then((data)=>{
      console.log(data);
      if(data && data.length > 0){
        setPageData(data[0])
      }
    });
  }, [])

  return (
    <>
      <Header emailText={homePageState.contactUs}
          phoneText={homePageState.PhoneNumber} />
          
<div class="full_width ds_page_banner bg-center" style={{background:pageData && pageData.banner?'url('+ baseUrl + pageData.banner.url  +')':'black'}}  >
	<div class="black_overlay">
		<div class="container">
			<div class="row">
				<div class="page_content_wrap">
					<div class="page_heading">
						<h1>{pageData.title}</h1>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
      <div id="courses" className="full_width contact_section" >
        <div className="container">
          <div className="row">
            {/* heading section */}
            <div className="content" >
              <ReactMarkdown
                children={pageData.content}
              />
            </div>
          </div>
        </div>
      </div>
       <Footer 
          aboutText={homePageState.About}
          addressText={homePageState.Address}
          emailText={homePageState.contactUs}
          phoneText={homePageState.PhoneNumber}
        />
    </>
  );
};

export default AboutPage;
