import Header from "./../compoents/common/Header";
import Footer from "./../compoents/common/Footer";
import BannerSlider from "./../compoents/Slider/BannerSlider";
import TestimonialSlider from "./../compoents/Slider/TestimonialSlider";
import CourseCard from "./../compoents/CourseCard.jsx";
import { useEffect, useState } from "react";
import { baseUrl, courseApi, homePageApi } from "../utils/apis";
import testimonialBg from "./../assets/images/1281677.jpg";
import learnDrivingBg from "./../assets/images/learn-to-drive-a-manual-1597958687.jpg";
import WOW from "wowjs";

const HomePage = () => {
  const [courses, setCourses] = useState([]);
  const [loader, setLoader] = useState(true);
  const [homePageState, setHomePageState] = useState({});

  useEffect(() => {
    setLoader(true);
    courseApi()
      .then((data) => {
        if (data) {
          setCourses(data);
        }
      })
      .then(() => {
        homePageApi().then((data) => {
          console.log(data);
          if (data) {
            setTimeout(() =>{
              setLoader(false);
            },2500)
            setHomePageState(data);
            new WOW.WOW().init();
          }
        });
      });
  }, []);


  return (
    <>
      <Header
        emailText={homePageState.contactUs}
        phoneText={homePageState.PhoneNumber}
      />
      <BannerSlider />
      <div className="preloader" style={{display:loader === true?'block':'none'}}>
        <div className="preloader_inner">
          <div className="loading_icon">
            <i className="flaticon flaticon-car-steering-wheel" />
          </div>
          <p>Loading...</p>
        </div>
      </div>
      <div className="service_section" id="service" style={{ display:loader === true?'none':'block' }} >
        <div className="container">
          <div className="row">
            {/* heading section */}
            <div className="heading_section">
              <h1>Our Services</h1>
              <span className="heading_icon">
                <i className="flaticon flaticon-car-steering-wheel" />
              </span>
            </div>
            {/* heading section */}
            {homePageState.Services &&
              homePageState.Services.map((service) => (
                <div
                  className="col-md-3 col-sm-6 col-xs-12"
                  key={`service` + service.id}
                >
                  <div
                    className="service_box fromright wow"
                    data-wow-delay=".5s"
                  >
                    <i className={service.icon} />
                    <h4>{service.Name}</h4>
                    <p>{service.Description}</p>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
      {/* testimonial section Start */}
      {homePageState.testimonial && (
        <div
          className="testimonial_section"
          id="more_courses"
          style={{
            backgroundImage: "url(" + testimonialBg + ")",
            backgroundAttachment: "fixed",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
          }}
        >
          <div className="black_overlay">
            <div className="container">
              <div className="row d-flex justify-content-center">
                <div className="col-md-10 col-xs-12 col-md-offset-1">
                  <TestimonialSlider
                    testimonials={
                      homePageState.testimonial ? homePageState.testimonial : []
                    }
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      {/* testimonial section End */}
      {/* Course section Start */}
      <div id="courses" className="courses_section">
        <div className="container">
          <div className="row">
            {/* heading section */}
            <div className="heading_section">
              <h1>Our Courses</h1>
              <span className="heading_icon">
                <i className="flaticon flaticon-car-steering-wheel" />
              </span>
            </div>
            {/* heading section */}
            {courses.map((course) => (
              <CourseCard
                key={`course` + course.id}
                title={course.Name}
                desc={course.Description}
                amount={course.Amount}
                course={course}
                image={baseUrl + course.Photo.url}
              />
            ))}
          </div>
        </div>
      </div>
      {/* Course section End */}
      {/* Skill section Start */}
      <div
        className="skills_section"
        style={{
          backgroundImage: "url(" + learnDrivingBg + ")",
          backgroundAttachment: "fixed",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "center",
        }}
      >
        <div className="black_overlay">
          <div className="container">
            <div className="row">
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="counter_figure zoom wow">
                  <i className="flaticon flaticon-happy" />
                  <h1
                    className="counter_num"
                    data-to={1500}
                    data-delay={100}
                    data-speed={4000}
                  >
                    {homePageState.HappyCustomersCount}
                  </h1>
                  <p>Happy Customers</p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="counter_figure zoom wow" data-wow-delay=".3s">
                  <i className="flaticon flaticon-baths-marker-point" />
                  <h1
                    className="counter_num"
                    data-to={15}
                    data-delay={100}
                    data-speed={4000}
                  >
                    {homePageState.LocationsCount}
                  </h1>
                  <p>Locations</p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="counter_figure zoom wow" data-wow-delay=".6s">
                  <i className="flaticon flaticon-delivery-man" />
                  <h1
                    className="counter_num"
                    data-to={12}
                    data-delay={100}
                    data-speed={4000}
                  >
                    {homePageState.ExpertiseCount}
                  </h1>
                  <p>years of Expertise</p>
                </div>
              </div>
              <div className="col-md-3 col-sm-6 col-xs-12">
                <div className="counter_figure zoom wow" data-wow-delay=".9s">
                  <i className="flaticon flaticon-car-steering-wheel" />
                  <h1
                    className="counter_num"
                    data-to={20}
                    data-delay={100}
                    data-speed={4000}
                  >
                    {homePageState.DrivingInstructorsCount}
                  </h1>
                  <p>Driving Instructors</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* skill section End */}
      {/* instruction section Start */}
      <div className="instructor_team_section">
        <div className="container">
          <div className="row">
            {/* heading section */}
            <div className="heading_section">
              <h1>Our Instructors</h1>
              <span className="heading_icon">
                <i className="flaticon flaticon-car-steering-wheel" />
              </span>
            </div>
            {/* heading section */}
            {homePageState.Instructors &&
              homePageState.Instructors.map((Instructor) => {
                return (
                  <div
                    className="col-md-3 col-sm-6 col-xs-12"
                    key={`homePage` + Instructor.id}
                  >
                    <div className="instruct_figure">
                      <div className="course_thumb thumb_hover">
                        <img
                          src={baseUrl + Instructor.photo.url}
                          alt=""
                          className="img-responsive img-fluid"
                        />
                      </div>
                      <div className="instruct_desc pb-3">
                        <h4>{Instructor.name}</h4>
                        <h5>{Instructor.position}</h5>
                        {/* <ul className="social_icons">
                    <li>
                      <a href="#">
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-google-plus" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-pinterest-p" />
                      </a>
                    </li>
                    <li>
                      <a href="#">
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                  </ul> */}
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      {/* instruction section End */}
      <Footer
        aboutText={homePageState.About}
        addressText={homePageState.Address}
        emailText={homePageState.contactUs}
        phoneText={homePageState.PhoneNumber}
      />
    </>
  );
};

export default HomePage;
