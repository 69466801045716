import Header from "./../compoents/common/Header";
import Footer from "./../compoents/common/Footer";
import { useEffect, useState } from "react";
import { pageDataApi, homePageApi, baseUrl, saveFeedback } from "../utils/apis";
import { useForm } from "react-hook-form";

const AboutPage = () => {
  const [homePageState, setHomePageState] = useState({});
  const [pageData, setPageData] = useState({});
  const [formSubmitted, setFormSubmitted] = useState(false);
  const { register, handleSubmit, watch, formState: { errors } } = useForm();
 
  useEffect(() => {
    homePageApi().then((data) => {
      console.log(data);
      if (data) {
        setHomePageState(data);
      }
    });
    pageDataApi("contactUs").then((data) => {
      console.log(data);
      if (data && data.length > 0) {
        setPageData(data[0]);
      }
    });
  }, []);

  return (
    <>
      <Header
        emailText={homePageState.contactUs}
        phoneText={homePageState.PhoneNumber}
      />

      {pageData && (
        <div
          class="full_width ds_page_banner bg-center"
          style={{
            background:
              pageData && pageData.banner
                ? "url(" + baseUrl + pageData.banner.url + ")"
                : "black",
          }}
        >
          <div class="black_overlay">
            <div class="container">
              <div class="row">
                <div class="page_content_wrap">
                  <div class="page_heading">
                    <h1>{pageData.title}</h1>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
      <div id="courses" className="courses_section">
        <div className="container">
          <div className="row">
            <div className="full_width contact_section">
              <div className="container">
                <div className="row">
                  <div className="col-sm-6 col-xs-12">
                    <ul className="contact_details">
                      <li className="fromleft wow" data-wow-delay=".3s">
                        <span className="icons">
                          <i
                            className="fa fa-envelope-open-o"
                            aria-hidden="true"
                          />
                        </span>
                        <div>
                          <h3>Our Email</h3>
                          <p>{homePageState.contactUs}</p>
                        </div>
                      </li>
                      <li className="fromleft wow" data-wow-delay=".6s">
                        <span className="icons">
                          <i className="fa fa-phone" aria-hidden="true" />
                        </span>
                        <div>
                          <h3>Our Phone</h3>
                          <p>{homePageState.PhoneNumber}</p>
                        </div>
                      </li>
                      <li className="fromleft wow" data-wow-delay=".9s">
                        <span className="icons">
                          <i className="fa fa-map-marker" aria-hidden="true" />
                        </span>
                        <div>
                          <h3>Our Address</h3>
                          <ul>
                          <li>{homePageState.Address}</li>
                          <li>{"Shop no 5 Shilpan plaza swaminarayan chowk near pdm collage gondal road rajkot"}</li>
                          <li>{"Near parapipaliya opp adrsh marble jamnagar road rajkot"}</li>
                          </ul>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div className="col-sm-6 col-xs-12">
                    {formSubmitted && <h1 className="thanksBtn" >Thanks for your feedback</h1>}
                    {!formSubmitted && <div className="contact_form"  >
                      <h3>Get In Touch</h3>
                      <div className="row">
                        <form onSubmit={handleSubmit((values)=>{
                          console.log('aa');
                          saveFeedback({
                            name:values.name,
                            email:values.email,
                            number:values.phone,
                            subject:values.subject,
                            message:values.message,
                          })
                          setFormSubmitted(true)
                        })} >
                          {Object.keys(errors).length > 0 && <div className="alert alert-danger">
                          <ul>
                          {errors.name && <li>Name field is required</li>}
                          {errors.email && <li>Email field is required</li>}
                          {errors.phone && <li>Phone field is required</li>}
                          {errors.subject && <li>Subject field is required</li>}
                          {errors.message && <li>Message field is required</li>}
                          </ul>
                          </div>}
                          <div className="col-md-6 col-xs-12">
                            <input
                              type="text"
                              name="name"
                              placeholder="Your Name"
                              id="u_name"
                              {...register("name", { required: true })}
                              />
                          </div>
                          <div className="col-md-6 col-xs-12">
                            <input
                              type="email"
                              name="email"
                              placeholder="Your Email"
                              id="u_email"
                              {...register("email", { required: true,email: true })}
                            />
                          </div>
                          <div className="col-md-6 col-xs-12">
                            <input
                              type="text"
                              name="phone"
                              placeholder="Your Phone"
                              id="u_phone"
                              {...register("phone", { required: true })}
                            />
                          </div>
                          <div className="col-md-6 col-xs-12">
                            <input
                              type="text"
                              name="subject"
                              placeholder="Your Subject"
                              id="u_sub"
                              {...register("subject", { required: true })}
                            />
                          </div>
                          <div className="col-lg-12 col-xs-12">
                            <textarea
                              name="message"
                              placeholder="Your Message"
                              id="u_message"
                              defaultValue={""}
                              {...register("message", { required: true })}
                            />
                          </div>
                          <div className="col-lg-12 col-xs-12">
                            <button
                              type="submit"
                              className="submit_btn red_btn"
                            >
                              Send
                            </button>
                          </div>
                          <div
                            className="col-lg-12 col-xs-12"
                            id="err_msg"
                          ></div>
                        </form>
                      </div>
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="map_section">
        <div id="contact_map">
        <iframe
              src={homePageState.googleIframeSrc}
              frameBorder="0"
              style={{ width: '100%',height: '100%'}}
              allowFullScreen=""
              aria-hidden="false"
              tabIndex="0"
            />
        </div>
      </div>
      <Footer
        aboutText={homePageState.About}
        addressText={homePageState.Address}
        emailText={homePageState.contactUs}
        phoneText={homePageState.PhoneNumber}
      />
    </>
  );
};

export default AboutPage;
